import React, { Component } from "react";
import { map } from "lodash";
import Slider from "react-slick";

import Header from "../components/header";
import Contact from "../components/contact";
import { settings_gift } from "../constants/slider";
import { links_convention } from "../constants/routes";

import gallery1 from "../images/galeria/convention";
import galleryA from "../images/galeria/convention_a";
import galleryB from "../images/galeria/convention_b";
import galleryC from "../images/galeria/convention_c";

import img1 from "../images/convention-1.jpg";
import img2 from "../images/convention-2.jpg";
import img3 from "../images/convention-3.jpg";
import img5 from "../images/convention-5.jpg";
import img6 from "../images/convention-6.jpg";

import imgMember from "../images/member-of.png";

class ConventionSupport extends Component {
  render() {
    return (
      <div className="convention container-fluid">
        <Header
          links={links_convention}
          title="Scuticchio Convention Support"
          type="convention"
        />

        <div className="row">
          <div className="col-sm-14">
            <div className="gallery">
              <Slider {...settings_gift()}>
                {map(gallery1, (img, key) => (
                  <div className="gallery__img" key={key}>
                    <img src={img} />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <div className="convention__gif">
              <Slider {...settings_gift(2)}>
                {map(galleryA, (img, key) => (
                  <div className="slider__img" key={key}>
                    <img src={img} />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="col-4">
            <div className="convention__gif">
              <Slider {...settings_gift(3)}>
                {map(galleryB, (img, key) => (
                  <div className="slider__img" key={key}>
                    <img src={img} />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="col-6">
            <div className="convention__gif">
              <Slider {...settings_gift()}>
                {map(galleryC, (img, key) => (
                  <div className="slider__img" key={key}>
                    <img src={img} />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>

        <article className="row " id="nosotros">
          <header className="col-md-6 offset-md-6 col-sm-12 offset-sm-1 col-14 block-text">
            <h2>BRINDAMOS SOLUCIONES AUDIOVISUALES</h2>
          </header>

          <figure className="col-sm-14">
            <img src={img1} className="img-responsive" alt=""/>
          </figure>

          <div className="col-md-6 offset-md-6 col-sm-12 offset-sm-1 col-14 block-text">
            <p>CONVENTION SUPPORT es la división audiovisual de SCUTICCHIO dedicada a proveer soluciones audiovisuales con alta tecnología para congresos, convenciones, ferias y exposiciones.</p>
            <p>Contamos con 30 años de experiencia en el mercado. Hemos prestado soluciones para las más exigentes necesidades de nuestros clientes. Trabajamos produciendo innovación y excelencia en toda escala de eventos, con pasión por el detalle.</p>
          </div>

          <figure className="col-sm-14 add-margin">
            <img src={img2} className="img-responsive" alt=""/>
          </figure>

          <div className="col-md-6 offset-md-6 col-sm-12 offset-sm-1 col-14 block-text">
            <p>Nuestras propias oficinas están ubicadas en el distrito audiovisual.</p>
            <p>El edificio dedicado a la atención personalizada de nuestros clientes, con área de planificación de eventos, IT (soluciones tecnológicas e informáticas), capacitación de personal, depósito de equipos y mantenimiento técnico.</p>
            <p>Contamos con logística propia con vehículos adaptados especialmente para el traslado de tecnología.</p>
            <p>Todo el personal de nuestra empresa está altamente capacitado, bilingüe y entrenado bajo normas Infocomm Internacional, debidamente uniformado, y es personal estable de Scuticchio The DJ Company.</p>
          </div>

          <figure className="col-sm-14 add-margin">
            <img src={img3} className="img-responsive" alt=""/>
          </figure>
        </article>

        <article className="row" id="servicios">
          <div className="col-md-4 offset-md-2 col-sm-12 offset-sm-1 block-text">
            <header>
              <h2>DISEÑO DE EVENTOS</h2>
            </header>
            <p>Desarrollamos nuestras producciones de eventos con la más innovadora tecnología del mercado.</p>
          </div>
        </article>

        <article className="row led">
          <div className="col-md-4 offset-md-2 col-sm-12 offset-sm-1 col-14 block-text">
            <header>
              <h2>PANTALLAS LED</h2>
            </header>
            <p>Pantallas de led gigantes HD de fondo negro, indoor/outdoor, para la realización de todo tipo de eventos corporativos, espectáculos al aire libre, sociales, etc. También contamos con totems de led digitales y podios de led.</p>
          </div>
        </article>

        <article className="row">
          <div className="col-md-4 offset-md-2 col-sm-12 offset-sm-1 col-14">
            <figure className="traduccion">
              <img src={img5} className="img-responsive" alt=""/>
            </figure>
          </div>
          <div className="col-md-4 offset-md-2 col-sm-12 offset-sm-1 col-14 block-text">
            <header>
              <h2>TRADUCCIÓN SIMULTÁNEA DIGITAL</h2>
            </header>

            <p>Convention Support utiliza en forma exclusiva en Argentina marca Taiden de traducción simultánea, 32 canales, de sistema infrarrojos digital de última generación; auriculares tipo vinchas; control de volumen e idiomas en display de lcd de la unidad receptora.</p>
            <p>Esta misma tecnología y equipamiento es el que utiliza la O.N.U. (Naciones Unidas) para sus congresos.</p>
          </div>
        </article>

        <article className="row">
          <figure className="col-sm-14 add-margin">
            <img src={img6} className="img-responsive" alt=""/>
          </figure>

          <div className="col-md-4 offset-md-2 col-sm-12 offset-sm-1 col-14 block-text">
            <p>Coordinamos, asistimos y dirigimos el diseño, desarrollo, puesta de los sistemas informáticos, el uso del software y el hardware.<br />
            Alquiler de equipos de impresión, all-in-one, i-pads, notebooks, tablets.<br />
            Sistema de acreditación para eventos.</p>
          </div>
          <div className="col-md-4 offset-md-2 col-sm-12 offset-sm-1 col-14 block-text">
            <header>
              <h2>CÁMARAS</h2>
            </header>
            <p>Brindamos la última tecnología en cámaras robotizadas.<br />
            PTZ, HD, 4K dirigidas remotamente.
            </p>
          </div>
        </article>

        <article className="row details">
          <div className="col-sm-12 offset-md-2 offset-sm-1">
            <h4>DETALLE DE OTROS SERVICIOS</h4>
          </div>
          <div className="col-md-4 offset-md-2 col-sm-6 offset-sm-1">
            <ul>
              <li>Diseño y coordinación de eventos</li>
              <li>Pantallas de led Pitch 4</li>
              <li>Proyectores DLP-3LCD</li>
              <li>Sistema delegado para conferencias</li>
              <li>Sistema de traducción simultánea infrarroja</li>
              <li>Votación interactiva wireless</li>
              <li>Tecnología IT</li>
              <li>Sistema de sonido alto impacto Line Array</li>
            </ul>
          </div>
          <div className="col-md-4 col-sm-6">
            <ul>
              <li>Cámaras CCTV Full HD-SDI</li>
              <li>Smart led 3D TV 50’’</li>
              <li>Video y audio para conferencias</li>
              <li>Alquiler de computadoras, laptop, tablets</li>
              <li>Staging</li>
              <li>Sistema de audio para refuerzo de palabras</li>
              <li>Intérpretes</li>
              <li>Asistentes y auxiliares de salón</li>
            </ul>
          </div>
        </article>

        <Contact subject="Consulta Web - Convention" />

        <section className="row ">
          <div className="col-sm-4 offset-sm-5">
            <div className="member">
              <img src={imgMember} alt="Member of"/>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ConventionSupport;
