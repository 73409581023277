export const settings = {
    accessibility: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    dots: false,
    fade: false,
    infinite: true,
    lazyLoad: true,
    pauseOnHover: false,
};

export const settings_gift = (speed = 1) => ({
    accessibility: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000 * speed,
    dots: false,
    fade: true,
    infinite: true,
    lazyLoad: true,
    pauseOnHover: false,
    slidesToShow: 1,
});
