import company_svg from "../images/scuticchio-the-dj-company.svg";
import convention_svg from "../images/scuticchio-convention-support.svg";
import concert_svg from "../images/tito-scuticchio-in-concert.svg";
import live_svg from "../images/scuticchio-live.svg";

import company_svg_white from "../images/scuticchio-the-dj-company-white.svg";
import convention_svg_white from "../images/scuticchio-convention-support-white.svg";
import concert_svg_white from "../images/tito-scuticchio-in-concert-white.svg";
import scuticchio_live_title from "../images/scuticchio-live-title.svg";

const baseUrl = process.env.APP_ENV === 'development' ? '' : '';

const root = {
    dj: {
        link: `${baseUrl}/the-dj-company`,
        label: 'Scuticchio The DJ Company',
        className: "home__item home__item--dj",
        img: company_svg,
        logo: company_svg_white,
    },
    convention: {
        link: `${baseUrl}/convention-support`,
        label: 'Scuticchio Convention Support',
        className: "home__item home__item--convention",
        img: convention_svg,
        logo: convention_svg_white,
    },
    concert: {
        link: `${baseUrl}/in-concert`,
        label: 'Tito Scuticchio in Concert',
        className: "home__item home__item--concert",
        img: concert_svg,
        logo: concert_svg_white,
    },
    live: {
        link: `${baseUrl}/live-streaming`,
        label: 'Scuticchio Live Streaming',
        className: "home__item home__item--live",
        img: live_svg,
        logo: scuticchio_live_title,
    }
};

export const links_dj = [
    {
        link: `${baseUrl}/the-dj-company#tito`,
        label: 'Tito'
    },
    {
        link: `${baseUrl}/the-dj-company#delfina`,
        label: 'Delfina'
    },
    {
        link: `${baseUrl}/the-dj-company#galeria`,
        label: 'Galería'
    },
    {
        link: `${baseUrl}/the-dj-company#contacto`,
        label: 'Contacto'
    }
];

export const links_convention = [
    {
        link: `${baseUrl}/convention-support#nosotros`,
        label: 'Nosotros'
    },
    {
        link: `${baseUrl}/convention-support#servicios`,
        label: 'Servicios'
    },
    {
        link: `${baseUrl}/convention-support#contacto`,
        label: 'Contacto'
    },
];

export const links_concert = [
    {
        link: `${baseUrl}/in-concert#contacto`,
        label: 'Contacto'
    },
];

export const links_live = [
  {
    link: `${baseUrl}/live-streaming#contacto`,
    label: 'Contacto'
  },
];

export const thanks = `${baseUrl}/muchasgracias`;

export const home = baseUrl;

export default root;
