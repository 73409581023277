import image0 from "./Galeria_CONVENTION_0000.jpg";
import image1 from "./Galeria_CONVENTION_0001.jpg";
import image2 from "./Galeria_CONVENTION_0002.jpg";
import image3 from "./Galeria_CONVENTION_0003.jpg";
import image4 from "./Galeria_CONVENTION_0004.jpg";

export default [
    image0,
    image1,
    image2,
    image3,
    image4,
];