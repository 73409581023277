export const address = "Crámer 1562";
export const cp = "C1426AOH";
export const city = "Ciudad Autónoma de Buenos Aires";
export const phone = "+54 11 4554 5630";
export const email = "info@scuticchio.com.ar";

export default [
    address,
    city,
    cp,
    phone,
    email,
];
