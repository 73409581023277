import React, { Component } from "react";
import Slider from "react-slick";
import { map } from "lodash";

import Header from "../components/header";
import Contact from "../components/contact";
import { links_dj } from "../constants/routes";
import { settings, settings_gift } from "../constants/slider";

import gallery1 from "../images/galeria/dj_1";
import gallery2 from "../images/galeria/dj_2";
import galleryA from "../images/galeria/dj_a";
import galleryB from "../images/galeria/dj_b";

class DjCompany extends Component {
  render() {
    return (
        <div className="container-fluid dj">
          <Header
            links={links_dj}
            title={'Scuticchio The Dj Company'}
            type="dj"
          />

          <div className="row">
            <div className="col-14">
              <div className="dj__gallery gallery">
                <Slider {...settings}>
                  {map(gallery1, (img, key) => (
                    <div className="gallery__img" key={key}>
                      <img src={img} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>

          <article className="row staff staff--tito" id="tito">
            <div className="col-md-4 offset-md-8 col-sm-8 offset-sm-5 col-14">
              <div className="block-text">
                <h2 className="title">TITO SCUTICCHIO <br />MARCA REGISTRADA</h2>
                <p>Hace más de treinta años desparramamos nuestro primer sonido al tiempo que encendimos nuestro primer spot. Después de esas tres décadas, musicalizadas con el gusto de los tiempos, seguimos iluminando eventos con el brillo de la experiencia pero sin dejar que la música y la luz reflejen solo el pasado.</p>
                <p>Ahora la gente habla de puestas, y allí estamos, con todo el equipamiento técnico, los más completos sistemas de sonido, sorprendente iluminación inteligente, efectos especiales, ambientaciones, imponentes pantallas de LED y algo más…, el sello de una empresa que jamás olvida que también la excelencia se alcanza con un agregado basado en el talento. La diferencia entre lo bueno y lo excepcional.</p>
              </div>
            </div>
          </article>

          <article className="row staff staff--delfina" id="delfina">
            <div className="col-md-4 offset-md-2 col-sm-8 offset-sm-1">
              <div className="block-text">
                <h2 className="title">LA GARANTÍA, UN ESTILO</h2>
                <p>Cada fiesta o cada evento es irrepetible para sus gestores, anfitriones o invitados. También lo es para nosotros, responsables, desde la trastienda, de que todo sea impecable en el gran escenario.</p>
                <p>De allí la porfía de mantenernos dentro de un estilo que garantice el éxito sin dejar nunca al costado el gusto de quienes vivirán la función, es decir los verdaderos protagonistas. Por eso, juntos, remixaremos la música, una melodía tan irrepetible como inolvidable.</p>
              </div>
            </div>
          </article>

          <div className="row">
            <div className="col-14">
              <div id="galeria" className="dj__gallery gallery">
                <Slider {...settings_gift()}>
                  {map(gallery2, (img, key) => (
                    <div className="gallery__img" key={key}>
                      <img src={img} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>

          <article className="row dj-service">
            <div className="col-md-4 col-7">
              <Slider {...settings_gift(2)}>
                {map(galleryA, (img, key) => (
                  <div className="dj-service__img" key={key}>
                    <img src={img} />
                  </div>
                ))}
              </Slider>
            </div>

            <div className="col-md-4 col-7">
              <Slider {...settings_gift(3)}>
                {map(galleryB, (img, key) => (
                  <div className="dj-service__img" key={key}>
                    <img src={img} />
                  </div>
                ))}
              </Slider>
            </div>

            <div className="col-md-6 col-14">
                <div className="dj-service__wrap">
                  <div className="dj-service__text">
                    <h2 className="title">UN IMPACTO PARA LOS SENTIDOS</h2>
                    <p>La música suena diáfana, en el tono justo. Pero, estos tiempos del LED reclaman otros acompañamientos que los vistan de la mejor manera para la función y los destellos de talento requieren más que un seguidor.</p>
                    <p>Entonces, el salón como el frente del edificio podrán ser vestidos por las mejores galas de nuestra iluminación. Equipos robotizados, cabezales móviles, rayos laser y sistemas computarizados crearan efectos insospechados para transformarlo todo más allá de la ilusión.</p>
                  </div>
                </div>
            </div>
          </article>

          <Contact type="dark" subject="Consulta Web - DJ" />
        </div>
    );
  }
}

export default DjCompany;
