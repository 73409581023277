import image0 from "./Streaming1.jpg";
import image1 from "./Streaming2.jpg";
import image2 from "./Streaming3.jpg";
import image3 from "./Streaming4.jpg";

export default [
  image0,
  image1,
  image2,
  image3
];
