import React from "react";
import { Switch, Route, BrowserRouter as Router, browserHistory } from "react-router-dom";

import Home from "./pages/home";
import InConcert from "./pages/in-concert";
import ConvertionSupport from "./pages/convention-support";
import DjCompany from "./pages/dj-company";
import LivePage from "./pages/live";
import withTracker from './withTracker';

// const baseUrl = process.env.APP_ENV === 'development' ? '' : '';

const Routes = () => (
  <Router location={browserHistory}>
    <Switch>
      <Route name="home" exact path={'/'} component={withTracker(Home)} />
      <Route name="in-concert" path={'/in-concert/:id?'} component={withTracker(InConcert)} />
      <Route name="dj-company" path={'/the-dj-company/:id?'} component={withTracker(DjCompany)} />
      <Route name="convention-support" path={'/convention-support/:id?'} component={withTracker(ConvertionSupport)} />
      <Route name="live" path={'/live-streaming/:id?'} component={withTracker(LivePage)} />
    </Switch>
  </Router>
);

export default Routes;
