import image0 from "./Galeria_CONVENTION_0000.jpg";
import image1 from "./Galeria_CONVENTION_0001.gif";
import image2 from "./Galeria_CONVENTION_0002.gif";
import image3 from "./Galeria_CONVENTION_0003.gif";
import image4 from "./Galeria_CONVENTION_0004.gif";
import image5 from "./Galeria_CONVENTION_0005.gif";
import image6 from "./Galeria_CONVENTION_0006.gif";
import image7 from "./Galeria_CONVENTION_0007.gif";
import image8 from "./Galeria_CONVENTION_0008.gif";
import imag11 from "./Galeria_CONVENTION_0011.jpg";
import imag12 from "./Galeria_CONVENTION_0012.jpg";
import imag13 from "./Galeria_CONVENTION_0013.jpg";

export default [
    image0,
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    imag11,
    imag12,
    imag13,
];
