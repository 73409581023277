import React, { Component } from "react";
import Slider from "react-slick";
import { map } from "lodash";

import Header from "../components/header";
import Contact from "../components/contact";
import { links_live } from "../constants/routes";
import { settings } from "../constants/slider";

import gallery1 from "../images/galeria/live";
import gallery2 from "../images/galeria/live_2";
import titleLive from "../images/scuticchio-live-title.svg";
import streamingImg from "../images/streaming-channels.png";

class Live extends Component {
  render() {
    return (
        <div className="container-fluid live">
          <Header
            links={links_live}
            title={'Scuticchio Live Streaming'}
            type="live"
          />

          <div className="row">
            <div className="col-14">
              <div className="dj__gallery gallery">
                <Slider {...settings}>
                  {map(gallery1, (img, key) => (
                    <div className="gallery__img" key={key}>
                      <img src={img} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>

          <article className="row">
            <div className="col-md-5 offset-md-2 col-14">
              <div className="block-text block-text__space">
                <h2><img src={titleLive} className="img-responsive" /></h2>
                <p>Gracias a nuestra tecnología de vanguardia, estamos en condiciones de realizar via streaming, conferencias, congresos, seminarios, presentaciones,etc.</p>
                <p>Estos pueden realizarse desde cualquier punto del país, y o del exterior, en un estudio de grabación, salón de eventos, hotel, o en sus propias instalaciones, todos ellos con sus respectivos requerimientos de técnica, como por ejemplo pantalla led, podio interactivo, etc como así también, puestas diseñadas según los requerimientos  necesarios del evento.</p>
                <p>Live Streaming significa alta tecnología en transmisión de audio y video en tiempo real ( Ud. ve lo que está pasando al instante), sin necesidad de bajar de internet archivo alguno.</p>
                <p>Hay sistemas de transmisión hogareños, de uso masivo, que no tienen la calidad de audio y video que se necesita,  para este tipo de eventos.</p>
                <p>En Scuticchio Live Streaming, ofrecemos alta calidad de transmisión IP, que nada envidia a una emisión de canal de TV.</p>
                <p>Disponemos de equipos profesionales de última generación, cámaras de video Full HD4k, con mando a distancia, micrófono headset,  para captar la disertación con la mayor fidelidad, envío encriptado de transmisión, switcher de producción en vivo, y con   una conexión a Internet apropiada se logra un streaming de calidad inigualable.</p>
              </div>
            </div>

            <div className="col-md-5 col-14">
              <div className="block-text block-text__space">
                <img className="img-responsive" src={streamingImg} />
                <p>Durante la emisión es posible tener chat online con oyentes, los cuales pueden interactuar con el orador, posibilidad de titular (Graff), nombre del disertante, logo animado, de la compañía que auspicia la charla, iluminación para óptima captura de imagen, e infinidad de opciones dignas de una emisión televisiva profesional.</p>
                <p>Scuticchio Live Streaming es la calidad que se necesita para eventos con transmisión online.</p>
              </div>
            </div>
          </article>

          <div className="photos">
            <div className="photos__wrap">
              {map(gallery2, (img, key) => (
                <div className="photos__item" key={`live${key}`}>
                  <img className="photos__img" key={`live${key}`} src={img} />
                </div>
              ))}
            </div>
          </div>

          <Contact type="live" subject="Live Streaming" />
        </div>
    );
  }
}

export default Live;
