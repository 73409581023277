import image0 from "./Galeria_CONVENTION_0000.jpg";
import image2 from "./Galeria_CONVENTION_0002.jpg";
import image3 from "./Galeria_CONVENTION_0003.jpg";
import image5 from "./Galeria_CONVENTION_0005.gif";
import image6 from "./Galeria_CONVENTION_0006.gif";
import image7 from "./Galeria_CONVENTION_0007.gif";
import image8 from "./Galeria_CONVENTION_0008.gif";
import image9 from "./Galeria_CONVENTION_0009.gif";
import imag11 from "./Galeria_CONVENTION_0011.gif";
import imag12 from "./Galeria_CONVENTION_0012.gif";
import imag13 from "./Galeria_CONVENTION_0013.gif";

export default [
    image0,
    image2,
    image3,
    image5,
    image6,
    image7,
    image8,
    image9,
    imag11,
    imag12,
    imag13,
];
