import React, { PureComponent } from 'react';
import ReactGA from 'react-ga';

import Routes from './routes';

ReactGA.initialize('UA-125903273-1');

class App extends PureComponent {
  render() {
    return <Routes />;
  }
}

export default App;
