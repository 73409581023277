import React, { Component } from "react";
import Header from "../components/header";

import { links_concert } from "../constants/routes";
import { isNull } from 'lodash';

import poster from "../images/poster.jpg"
import Contact from "../components/contact";

class DjCompany extends Component {
  render() {
    const urlVideo = '//scuticchio.com.ar/videos/scuticchio-in-concert';
    return (
      <div className="container-fluid concert">
        <Header
          links={links_concert}
          title={'Tito Scuticchio in Concert'}
          type="concert"
        />

        <section className="row">
          <div className="col-sm-14">
            <div className="video_wrapper">
              <video preload="yes" autoPlay={false} controls poster={poster} className="video_item">
                <source src={`${urlVideo}.mp4`} type='video/mp4' />
                <source src={`${urlVideo}.webm`} type='video/webm' />
                <source src={`${urlVideo}.ogg`} type='video/ogg' />
                {'Your browser does not support the video tag.'}
              </video>
            </div>
          </div>
        </section>

        <article className="row">
          <div className="col-md-4 offset-md-5 col-sm-8 offset-sm-3">
            <div className="block-text">
              <p>Tito Scuticchio In Concert es un show en vivo muy cuidado y exclusivo en el que Tito, acompañado de músicos, toca durante 45 minutos lo mejor de los ‘80 y ‘90 con vinilos y remix propios inéditos. Una aparición estelar en la noche, no importa cual sea el evento o el lugar. También hay temas nuevos además de clásicos con el inconfundible sello de Tito, ése que siempre lo distinguió: la capacidad de transformar cualquier celebración en un momento inolvidable.</p>
            </div>
          </div>
        </article>

        <Contact type="dark" subject="Consulta Web - InConcert" />
      </div>
    );
  }
}

export default DjCompany;
