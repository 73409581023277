import React, { Component } from "react";
import PropType from "prop-types";
import { every, isEmpty, forEach } from "lodash";
import { address, city, cp, phone, email } from "../constants/address";
import { withRouter } from "react-router-dom";
import ReactGA from 'react-ga';

class Contact extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.textInput = [];
    this.state = {
      allow: false,
      emailActive: true,
      phoneActive: false,
      thanks: false
    };
    this.setTextInputRef = this.setTextInputRef.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.showThanks = this.showThanks.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  handleKeyUp(event) {
    const values = [
      { 'complete': !isEmpty(this.textInput[0].value) },
      { 'complete': !isEmpty(this.textInput[1].value) || !isEmpty(this.textInput[2].value) },
      { 'complete': !isEmpty(this.textInput[3].value) }
    ];
    this.setState({
      allow: every(values, 'complete')
    })
  }

  setTextInputRef(element) {
    this.textInput.push(element);
  }

  selectOption(event) {
    this.setState({
      emailActive: event.target.name === 'email',
      phoneActive: event.target.name === 'phone'
    });
  }

  showThanks() {
    this.setState({
      thanks: true
    });
  }

  onClose() {
    this.props.history.goBack();
    this.setState({
      thanks: false
    });
  }

  onSubmit(event) {
    event.preventDefault();
    if (!this.state.allow) {
      return;
    }
    const url = '/FormMail.php';

    let formData = new FormData();
    formData.append('name', this.textInput[0].value);
    if (this.state.emailActive) {
      formData.append('email', this.textInput[1].value);
    } else {
      formData.append('phone', this.textInput[2].value);
    }
    formData.append('message', this.textInput[3].value);
    formData.append('recipient', 'info@scuticchio.com.ar');
    formData.append('subject', this.props.subject);
    //
    const opt = {
      recipient: 'test@scuticchio.com.ar',
      // recipient: 'info@scuticchio.com.ar',
      name: this.textInput[0].value,
      message: this.textInput[3].value,
      subject: this.props.subject,
      email: this.textInput[1].value,
      phone: this.textInput[2].value,
    };
    // if (this.state.emailActive) {
    //   opt.email = this.textInput[1].value;
    // } else {
    //   opt.phone = this.textInput[2].value;
    // }

    ReactGA.event({
      category: 'Contacto',
      action: 'Envio',
      ...opt,
    });

    fetch(url, {
      method: 'post',
      // headers: {
      //   'Accept': 'application/json',
      //   'Content-Type': 'application/json',
      // },
      body: formData
      // body: JSON.stringify(opt)
      // body: {
      //   "recipient": opt.recipient,
      //   "name": opt.name,
      //   "message": opt.message,
      //   "subject": opt.subject,
      //   "email": opt.email,
      //   "phone": opt.phone,
      // }
    })
    .then(res => {
      if(res.ok) {
        forEach(this.textInput, field => {
          field.value = '';
        });
        this.setState(() => ({
          thanks: true, allow: false
        }), () => {
          const current = this.props.history.location.pathname;
          this.props.history.push(`${current}/gracias`);
        });
      }
    })
    .catch(error => {
      console.log('Request failure: ', error);
    });
  }

  render() {
    const {
      onClose,
      onSubmit,
      selectOption,
      props: { form, type },
      state: { allow, emailActive, phoneActive, thanks }
    } = this;
    return (
        <div className={`contact contact--${type}`} id="contacto">
          <div className={`contact__inner`}>
            <h3 className="title">Contacto</h3>

            {thanks && <div className="thanks"><div onClick={onClose}><span>{'¡Muchas Gracias!'}</span></div></div>}

            {form && <div className="contact__form">
              <form action="/cgi-bin/FormMail.cgi" onSubmit={onSubmit} method="post" autoComplete="off">
                <label className="contact__field" htmlFor="name">
                  <input
                    name="name"
                    id="name"
                    type="text"
                    placeholder="Nombre y Apellido"
                    ref={this.setTextInputRef}
                    onKeyUp={this.handleKeyUp}
                  />
                </label>
                <label className={`contact__field contact__field--with-icon ${emailActive && 'contact__field--selected'}`} htmlFor="email" onClick={selectOption}>
                  <input
                    name="email"
                    id="email"
                    type="email"
                    placeholder="E mail"
                    ref={this.setTextInputRef}
                    onKeyUp={this.handleKeyUp}
                  />
                </label>
                <label className={`contact__field contact__field--with-icon ${phoneActive && 'contact__field--selected'}`} htmlFor="phone" onClick={selectOption}>
                  <input
                    name="phone"
                    id="phone"
                    type="text"
                    placeholder="Teléfono"
                    ref={this.setTextInputRef}
                    onKeyUp={this.handleKeyUp}
                  />
                </label>
                <label className="contact__textarea" htmlFor="message">
                  <textarea
                    name="message"
                    id="message"
                    type="text"
                    placeholder="Mensaje"
                    ref={this.setTextInputRef}
                    onKeyUp={this.handleKeyUp}
                  />
                </label>

                <div className="contact__submit">
                  <button type="submit" disabled={!allow}>Enviar</button>
                </div>
              </form>
            </div>}

            <p className="contact__address">
                <span>{address}</span>
                <span>{`${cp} - ${city}`}</span>
                <span className="contact__phone">{'Teléfono'}</span>
                <span className="nowrap"><a href={`tel:${phone.replace(/ /g, '')}`}>{phone.replace(' 11 ', ' (11) ')}</a></span>
                <a href={`mailto:${email}`}>{email}</a>
            </p>
          </div>

          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3285.331022648849!2d-58.45787588477118!3d-34.570489780468215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb5da4729da83%3A0x6d297258b5f52d42!2sCr%C3%A1mer+1562%2C+C1426AOH+CABA!5e0!3m2!1ses-419!2sar!4v1535080046131"
              width={800}
              height="300"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
    );
  }
}

Contact.propTypes = {
  form: PropType.bool,
  type: PropType.string,
};

Contact.defaultProps = {
  form: true,
  type: 'light',
};

export default withRouter(Contact);
